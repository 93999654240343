import logo from './logo.svg';
import './css/App.css';
import { useState } from 'react';
import Lhd_sotr from './Lhd_sotr.js';
import Lhds_t from './Lhds_t.js';
import { mec_name } from './funcs.js'

import { Context } from './Context.js';

let grss = []; let profs = []; let lscs = []; let lhds = []; let sotrs = []


function App(props) {
  const [base, Setbase] = useState(' ')

  if (base == '' || base == ' ') {
    return (
      <div>
        <Select_base Setbase={Setbase} />
      </div>
    )
  }
  let x
  let w_sotr = {}
  if ("sotr" in props && props.sotr > 0) {

    for (let z = 0; z < sotrs.length; z++) { // выведет 0, затем 1, затем 2
      if (sotrs[z].kod === props.sotr) {
        w_sotr = sotrs[z]
        w_sotr.god = "2024"
        w_sotr.sotr = props.sotr
        //alert(Object.keys(w_sotr))
        break
      }
    }
    return (
      <div>
        <Lhd_sotr sotr={w_sotr} lhds={lhds} lscs={lscs} />
      </div >
    )
  }
  return (
    <div>
      <Lhds_t lscs={lscs} lhds={lhds} />
    </div >
  )
}

function init_all() {
  grss.sort(function (a, b) {
    if (a.sq > b.sq)
      return 1;
    else
      return -1;
  })


  lhds.forEach((lhd) => {

    if (!("kopb" in lhd))
      lhd.kopb = "0"
    if (!("nmag" in lhd) || lhd.nmag == " ")
      lhd.nmag = "11111"
    
    lhd.fio = ""; lhd.dprim = ""; lhd.duvol = ""
    for (let z = 0; z < sotrs.length; z++) { // выведет 0, затем 1, затем 2
      if (sotrs[z].kod === lhd.sotr) {
        if ("dprim" in sotrs[z])
          lhd.dprim = sotrs[z].dprim
        if ("duvol" in sotrs[z])
          lhd.duvol = sotrs[z].duvol
        if ("fio" in sotrs[z]) {
          let w_fio = sotrs[z].fio.split(' ')
          lhd.fio = w_fio[0]
          if (w_fio[1])
            lhd.fio = lhd.fio + ' ' + w_fio[1].substring(0, 8)
          if (w_fio[2])
            lhd.fio = lhd.fio + ' ' + w_fio[2].substring(0, 5)
        }
        break
      }
    }
    lhd.dolg = " ";

    if (lhd.prof > 0) {
      for (let f = 0; f < profs.length; f++) { // выведет 0, затем 1, затем 2
        if (profs[f].kod === lhd.prof) {
          if (profs[f].name == "")
            profs[f].name = " asssssss"

          let w_dolg = profs[f].name.split(' ')
          lhd.dolg = w_dolg[0]
          if (w_dolg[1])
            lhd.dolg = lhd.dolg + ' ' + w_dolg[1].substring(0, 4)
          if (w_dolg[2])
            lhd.dolg = lhd.dolg + ' ' + w_dolg[2].substring(0, 4)
          break
        }
      }

    }
    else
      lhd.dolg = "111___222"
    
  })

  let w_grs = {}
  lscs.forEach(lsc => { // выведет 0, затем 1, затем 2

    for (let z = 0; z < grss.length; z++) { // выведет 0, затем 1, затем 2
      if (grss[z].kod === lsc.grs) {
        w_grs = grss[z]
        break
      }
    }
    lsc.ngrs = w_grs.name
    lsc.name = w_grs.name
    if (lsc.ndog && lsc.nach) {
      lsc.name += ' ' + lsc.ndog + ' от ' + lsc.ddog
    }
    if ('bdog' in lsc && 'edog' in lsc && lsc.nach) {
      let w_bdog = lsc.bdog.split('/')
      let w_edog = lsc.edog.split('/')
      if (w_bdog[1] === w_edog[1])
        lsc.name += ' c ' + w_bdog[0] + ' по ' + w_edog[0] + ' ' + mec_name(w_bdog[1])
    }

    if (!("nach" in lsc) && !("uder" in lsc))
      lsc.name += ' ' + lsc.sumr + ' руб'
    if (lsc.rab)
      lsc.name += ' ' + w_grs.np1 + ' ' + lsc.rab
    if (lsc.rabt)
      lsc.name += ' ' + w_grs.np2 + ' ' + lsc.rabt

    // if (lsc.mec !== lsc.mecb)
    //   lsc.name += '///' + mec_name(lsc.mecb) + ' ' + lsc.godb

  })
}
function Select_base(props) {

  return (
    <div id="log" className="login">

      Login   <input id="login" type="text" />
      Pass    <input id="pass" type="text" />
      <button onClick={vvod}>  Вход</button>

    </div>
  )
  function vvod() {
    let bases = 'json/'
    let log = document.getElementById("log")
    let base = document.getElementById("login").value

    let pass = document.getElementById("pass").value
    // alert(base + ' ' + pass)
    if ((base == "su2" && pass == "1221")
      || (base == "su3" && pass == "1331")
      || (base == "soc" && pass == "1441")
      || (base == "acent" && pass == "1551")
      || (base == "pred" && pass == "1661")
      || (base == "mono" && pass == "1771")
      || (base == "super" && pass == "1881")
    ) {
      //alert(bases + base + '/grs.json')

      fetch(bases + base + '/grs.json', { cache: 'no-store' })
        .then(response => response.json())
        .then(x_ar => {
          for (let i in x_ar) {
            grss.push(x_ar[i])
          }
          //  alert('grs ' + grss.length)
          fetch(bases + base + '/lhd.json', { cache: 'no-store' })
            .then(response => response.json())
            .then(x_ar => {
              for (let i in x_ar) {
                lhds.push(x_ar[i])
              }
              fetch(bases + base + '/lsc.json', { cache: 'no-store' })
                .then(response => response.json())
                .then(x_ar => {
                  for (let i in x_ar) {
                    lscs.push(x_ar[i])
                  }
                  fetch(bases + base + '/sotr.json', { cache: 'no-store' })
                    .then(response => response.json())
                    .then(x_ar => {
                      for (let i in x_ar) {
                        sotrs.push(x_ar[i])
                      }
                      fetch(bases + base + '/prof.json', { cache: 'no-store' })
                        .then(response => response.json())
                        .then(x_ar => {
                          for (let i in x_ar) {
                            profs.push(x_ar[i])
                          }
                          init_all()
                          // log.remove()
                          props.Setbase(base)
                        })
                    })
                })
            })
        })
    }
  }
}

export default App;

