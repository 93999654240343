import { useState } from 'react';
import React from 'react';
import { mec_name, printDiv } from './funcs.js'

import './css/lhd_f.css';
function Lhd_sotr(props) {
    const lhds = props.lhds
    const lscs = props.lscs
    const [show, Setshow] = useState("Показать")
    const cur = props.sel
    
    //alert(Object.entries(cur))
    const [sgod, Setsgod] = useState(cur.god)

    let w_lhds = []; w_lhds.length = 0
    let w_lscs = []; w_lscs.length = 0
    let w_str = []; w_str.length = 0
    let w_lhd = {}

    for (let i = 0; i < lhds.length; i++) {
        if (lhds[i].sotr == cur.sotr && lhds[i].god == cur.god) {
            if (props.mec == " " || props.mec && props.mec == lhds[i].mec)
                w_lhds.push(lhds[i])
        }

    }

    for (let i = 0; i < lscs.length; i++) {
        let x_lscs = lscs[i]
        if (props.s_grs > 0 && props.s_grs !== x_lscs.grs)
            continue
        if (x_lscs.sotr == cur.sotr && x_lscs.god == cur.god)
            if (props.mec == " " || props.mec && props.mec == x_lscs.mec)
                w_lscs.push(x_lscs)

    }

    w_lscs.sort(function (a, b) {
        if ((a.god - b.god) > 0 || (a.god === b.god && (a.mec - b.mec) > 0))
            return 1;
        else
            if (a.god === b.god && a.mec === b.mec)
                if ((a.godb - b.godb) > 0 || (a.godb === b.godb && (a.mecb - b.mecb) > 0))
                    return 1;
                else
                    if (a.godb === b.godb && a.mecb === b.mecb)
                        if (a.sq > b.sq)
                            return 1;
                        else
                            return -1;
                    else
                        return -1
            else
                return -1

    })

    let cur_mec = 0
    w_lscs.forEach((lsc) => {

        if (cur_mec !== lsc.mec) {
            cur_mec = lsc.mec
            for (let x = 0; x < w_lhds.length; x++) {
                if (w_lhds[x].mec === cur_mec) {
                    w_str.push({
                        "sq": " "
                        , "kopb": w_lhds[x].kopb
                        , "name": mec_name(w_lhds[x].mec)
                        , "mec": w_lhds[x].mec
                        , "rabt": w_lhds[x].rabt
                        , "oklad": w_lhds[x].oklad
                        , "nach": w_lhds[x].nach
                        , "uder": w_lhds[x].uder
                    })
                    break
                }
            }
        }
        w_str.push({
            "sq": lsc.sq
            , "name": lsc.name
            , "mec": lsc.mec
            , "grs": lsc.grs
            , "nach": lsc.nach
            , "uder": lsc.uder
            , "god": lsc.god, "mec": lsc.mec
            , "godb": lsc.godb, "mecb": lsc.mecb
        })
    })
    let s_hide = ""
    if (show == "Скрыть")
        s_hide = ""
    else
        s_hide = "hid"


    let srows = w_str.map((ar, index) => {
        if (ar.kopb = "0")
            ar.kopb = ""
        if (ar.sq === ' ')
            return (
                <tr id={"mec" + "_" + ar.mec} onClick={open_mec} className="tr_mec">
                    <td colSpan="2">{ar.kopb}  {ar.name} Время {ar.rabt} Оклад {ar.oklad}</td>
                    <td className="sumr">+{ar.nach}</td>
                    <td className="sumr">-{ar.uder}</td>
                </tr>
            )
        else
            if ((ar.god - ar.godb) < 0 || (ar.god === ar.godb && (ar.mec - ar.mecb) < 0)) {

                return (
                    <tr className="tr_lsc" >

                        <td className="sq">{ar.sq} </td>
                        <td className="ngrs"  >{ar.name}
                            <span  >
                                /за {mec_name(ar.mecb)} {ar.godb}
                            </span>
                        </td>
                        <td className="sumr">{ar.nach}</td>
                        <td className="sumr">{ar.uder}</td>

                    </tr>
                )
            }
            else
                if ((ar.god - ar.godb) > 0 || (ar.god === ar.godb && (ar.mec - ar.mecb) > 0)) {
                    return (
                        <tr className="tr_lsc" >

                            <td className="sq">{ar.sq} </td>
                            <td className="ngrs" >{ar.name}
                                <span  >
                                    /за {mec_name(ar.mecb)} {ar.godb}
                                </span>
                            </td>
                            <td className="sumr">{ar.nach}</td>
                            <td className="sumr">{ar.uder}</td>

                        </tr>
                    )
                }
                else {
                    return (
                        <tr className="tr_lsc" id={"grs" + "_" + ar.grs + "@" + "mec" + "_" + ar.mec}>

                            <td className="sq">{ar.sq} </td>
                            <td className="ngrs" colSpan="1" >{ar.name} </td>
                            <td className="sumr">{ar.nach}</td>
                            <td className="sumr">{ar.uder}</td>

                        </tr>
                    )
                }
    })

    return (
        <div id="div_lhd_f" className="lhd_f"  >
            <div className="div_head">
                <p> {cur.sotr + ' ' + cur.fio + ' ' + cur.nmag + ' ' + cur.dolg}</p>
                <button onClick={prior_lhd}> Назад </button>
                <span > {cur.god}  </span>
                <button onClick={next_lhd}> Вперед</button>
                <span>.......<button id="grs_show" onClick={grs_hid}> {show}</button></span>
                <button onClick={prt_div}>Печать  </button>
            </div>
            <div className="div_table">
                <table id="table_lhd_f" style={{ "table-layout": "fixed" }}>
                    <thead>
                        <tr>

                            <th className="sq"> Код</th>
                            <th className="ngrs"  > Операция &emsp;</th>
                            <th  > Начисл.</th>
                            <th  > Удерж.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {srows}
                    </tbody>
                </table>
            </div>

        </div>

    )
    function prt_div() {

        printDiv("div_lhd_f_god")
    }
    function grs_hid(e) {

        document.querySelectorAll('.tr_lsc').forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }

        })

        if (show === 'Показать') {
            Setshow('Скрыть')

        }
        else
            Setshow('Показать')



    }

    function prior_lhd() {
        cur.god = cur.god - 1
        Setsgod(cur.god)
    }
    function next_lhd() {

        cur.god++
        Setsgod(cur.god)
    }

    function open_mec(e) {
        const pmec = e.currentTarget.id
        document.querySelectorAll('.tr_grs' + '.' + pmec).forEach((el) => {
            if (el.classList.contains('hid')) {
                el.classList.remove('hid')
            } else {
                el.classList.add('hid');
            }

        })

    }
}



export default Lhd_sotr;